<template>
	<div class="page" v-loading="loading" element-loading-text="正在打印" element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.5)" customClass="myloading">
		<div class="container">
			<div class="con">
				<!-- 	<div class="select" style='margin-bottom: 20px;'>
					<span style='font-weight: bold;'>打印状态：</span>
					<el-select v-model="query.is_print" placeholder="请选择" @change="change">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
 -->
				<!-- <el-button type="danger">批量删除</el-button> -->
				
				
				
			
				
				<el-table :data="fileList" style="width: 100%" @selection-change="handleSelectionChange">

					<!-- 		<el-table-column type="selection" width="85">

					</el-table-column> -->
					<el-table-column prop="id" label="编号" width="180">
					</el-table-column>
					<el-table-column label="类型" width="180">
						<template slot-scope="scope">
							<el-tag v-if="scope.row.print_type == 1">文档</el-tag>
							<el-tag v-if="scope.row.print_type == 2">照片</el-tag>
							<el-tag v-if="scope.row.print_type == 3">证件照</el-tag>
							<el-tag v-if="scope.row.print_type == 5">身份证</el-tag>
							<el-tag v-if="scope.row.print_type == 6">复印文件</el-tag>
							<el-tag v-if="scope.row.print_type == 7">扫描文件</el-tag>
							<el-tag v-if="scope.row.print_type == 8">复印身份证</el-tag>
						</template>
					</el-table-column>


					<el-table-column label="文件" width="180">
						<template slot-scope="scope">
							<el-image
								v-if="scope.row.print_type !=1 && scope.row.print_type !=7 && scope.row.print_type !=6"
								style="width: 50px; height: 50px" :src="$domain+scope.row.file_url"
								:preview-src-list="srcList">
							</el-image>

							<!-- 	<router-link :to="'/pdf?url='+$domain+scope.row.file_url" v-else>
								<el-button type="primary" size="small">预览</el-button>
							</router-link>
							 -->
	
								<el-button v-else @click="handlePrint(scope.row)" type="primary" size="small">预览</el-button>
					
						</template>
					</el-table-column>

					<el-table-column prop="file_type" label="文件类型" width="180">
					</el-table-column>
					<el-table-column prop="create_time" label="创建时间">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button size="mini" @click="handlePrint(scope.row)">打印</el-button>
							<el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>


				<div class="pointpage">
					<el-pagination @current-change="handleCurrentChange" :current-page.sync="query.page"
						:page-size="query.limit" layout="total, prev, pager, next" :total="total">
					</el-pagination>
				</div>


				<el-dialog :visible.sync="spec" width="35%" :before-close="handleClose">
					<div>请选择规格</div>


					<el-form :model="wxData" :rules="rules" ref="wxData" label-width="100px" class="demo-wxData">
						<el-form-item label="打印数量" prop="name">
							<el-input-number v-model="wxData.print_number" :min="1" @change="sumMoney()" label="描述文字">
							</el-input-number>
						</el-form-item>

						<el-form-item label="打印类型">
							<el-radio-group v-model="wxData.paper_type" @change="sumMoney()">
								<el-radio label="1" border
									v-if="currObj.print_type == 1  || currObj.print_type == 6 || currObj.print_type == 7  || currObj.print_type == 5 || currObj.print_type == 8  || currObj.print_type == 2 "
									:disabled="dis_hb">



									黑白</el-radio>
								<el-radio label="3" border v-if="currObj.print_type == 6 || currObj.print_type == 7">
									黑白(去底色)</el-radio>


								<el-radio label="2" border>彩色</el-radio>

							</el-radio-group>
						</el-form-item>

						<el-form-item label="打印规格" prop="print_type" v-if="currObj.print_type == '1' || currObj.print_type == '6' || currObj.print_type == '7'">
							<el-radio-group v-model="wxData.print_type" @change="sumMoney()">
								<el-radio label="1" border v-if="model == 2">A3</el-radio>
								<el-radio label="2" border>A4</el-radio>
							</el-radio-group>
						</el-form-item>

						<el-form-item label="打印类型类型" v-if="currObj.print_type == '1' || currObj.print_type == '6' || currObj.print_type == '7'">
							<el-radio-group v-model="wxData.print_ctype" @change="sumMoney()">
								<el-radio label="1" border>单面</el-radio>
								<el-radio label="2" border>双面</el-radio>
							</el-radio-group>
						</el-form-item>


						<!-- 证件照 -->
						<el-form-item label="相片尺寸" v-if="currObj.print_type == '3'">
							<el-radio-group v-model="wxData.print_type" @change="sumMoney()">
								<el-radio label="3" border>一寸</el-radio>
								<el-radio label="4" border>两寸</el-radio>
								<el-radio label="5" border>大一寸</el-radio>
								<el-radio label="6" border>大两寸</el-radio>
							</el-radio-group>
						</el-form-item>

						<!-- 照片 -->
						<el-form-item label="纸张类型" v-if="currObj.print_type == '2'">
							<el-radio-group v-model="wxData.print_type" @change="sumMoney()">
								<el-radio label="2" border>文档(a4纸)</el-radio>
							</el-radio-group>

							<el-radio-group v-model="wxData.print_type" @change="sumMoney()">
								<el-radio label="7" border>照片(6寸)</el-radio>
							</el-radio-group>

						</el-form-item>


						<el-form-item label="文件排版" v-if="currObj.print_type == '1' || currObj.print_type == '6' || currObj.print_type == '7' ">
							<el-radio-group v-model="wxData.print_typeset">
								<el-radio label="1" border>竖版</el-radio>
								<el-radio label="2" border>横版</el-radio>
							</el-radio-group>
						</el-form-item>



						<el-form-item label="是否群打印" v-if="$store.state.userinfo.is_enterprise == 1">
							<el-switch v-model="isGroup"></el-switch>
						</el-form-item>

						<el-form-item label="群打印成员" v-if="isGroup">
							<el-checkbox-group v-model="ids">
								<el-checkbox :label="item.id" name="type" v-for="(item,index) in accList">
									{{ item.real_name }}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>





						<el-form-item label="价格">
							<p>¥ {{ price }}</p>
						</el-form-item>

						<el-form-item>

							<el-button :loading="previewLoading" type="primary" @click="preview()">
								打印预览</el-button>



						</el-form-item>




						<el-form-item>
							<el-button type="success" @click="pay('wx')">微信支付</el-button>
							<el-button type="success" @click="pay('ye',1)">余额支付</el-button>
							<el-button type="success" @click="pay('ye',2)"
								v-if="$store.state.userinfo.is_enterprise == 1">企业支付</el-button>
						</el-form-item>
					</el-form>



				</el-dialog>



				<el-dialog title="微信扫码支付" :visible.sync="showc" width="20%" :before-close="wxclose"
					style="text-align: center;">

					<vue-qr :text="code" :size="200" style="margin:0 auto;width:200px; height:200px"></vue-qr>

				</el-dialog>


				<Print ref="print"></Print>





			</div>

		</div>
	</div>

</template>

<script>
	import Print from "@/components/Print.vue"

	import vueQr from 'vue-qr'
	import {
		getLodop
	} from '../../utils/LodopFuncs'
	export default {
		components: {
			vueQr,
			Print
		},

		data() {
			return {
				files_id: [],
				dis_hb: false,
				ids: [],
				query: {
					page: 1,
					limit: 5,
					print_type: 0
				},
				total: 0,
				fileList: [],
				accList: [],
				isGroup: false,
				spec: false,
				showc: false,
				printOk: false,
				loading: false,
				srcList: [],
				options: [{
					value: 0,
					label: '全部',
				}, {
					value: 1,
					label: '文档',
				}, {
					value: 2,
					label: '图片',
				}, {
					value: 3,
					label: '证件照',
				}, {
					value: 5,
					label: '身份证',
				}, {
					value: 6,
					label: '复印',
				}],
				wxData: {
					type: "",
					task_type: '',
					paper_type: '',
					print_type: '2', //A4
					print_number: '1',
					print_count: '',
					print_file: '',
					print_hb_file: '',
					print_hb_qds_file: '',
					print_ctype: '1',
					money_type: '',
					print_typeset: '1'
				},

				sumMoneyData: {
					type: '',
					page: '',
					number: '',
					ctype: '',
					paper_type: ''
				},
				code: '',
				price: 0,
				pic: '',
				currObj: {},
				rules: {},
				obCode: '1', //job码
				printStatus: 1, //打印状态
				model: 0,

				previewLoading: false
			}



		},
		created() {
			this.getFileList();
			this.model = localStorage.getItem('model')
			//// console.log('vuex',this.$store.state.userinfo.is_enterprise)
			if (this.$store.state.userinfo.is_enterprise == 1) {
				this.AccountList();
			}
		},
		methods: {

			wordDetail(url) {
				console.log(url)
				// 微软提供的方法
				window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url,'_system')
				
				//window.open("https://view.xdocin.com/view?src=" + encodeURIComponent(url));
				
			},


			handleSelectionChange(obj) {
				//   this.multipleSelection = val;

				//console.log('---', val)

			},

			handleClose() {
				this.spec = false
			},

			change() {
				this.query.page = 1;
				this.query.is_print = this.query.is_print;

				// console.log('aa', this.query.is_print)
			},

			wxclose() {
				clearTimeout(this.timer);
				this.showc = false;
			},


			async AccountList() {
				let query = {
					limit: 1000,
					page: 1
				}
				let res = await this.$api.AccountList(query);
				this.accList = res.data.rows;
				// console.log('acc', this.accList)
			},



			//预览文件
			preview() {

				console.log('-----', this.wxData);


				console.log('预览详情', this.wxData);
				this.$refs.print.order(this.wxData, 'previewfile')

			},






			//创建任务
			async saveTask(type) {
				let data = {
					'type': this.wxData.task_type,
					'is_mass': 2,
					'paper_type': this.wxData.paper_type,
					'print_file': this.wxData.print_file,
					'print_hb_file': 'aa',
					'print_count': this.wxData.print_count,
					'print_number': this.wxData.print_number,
					'user_data': this.ids,
					'money_type': this.wxData.money_type,
					'print_ctype': this.wxData.print_ctype,
					'print_type': this.wxData.print_type,
					'print_typeset': this.wxdata.print_typeset
				}
				// console.log('任务参数', data);

				let res = await this.$api.saveTask(data);

				if (res.code == 200) {
					// console.log('任务', res)
					let param = {
						'id': res.id,
						'type': type + 1
					}
					this.orderPay(param, res.order_number)
				} else {
					this.$message.error(res.msg)
				}



			},


			async OrderDetail(data) {

				try {
					let res = await this.$api.OrderDetail(data);
					if (res.code == 200) {
						// console.log('订单详情222', res);

						if (res.data.order_status == '1') {

							console.log('订单详情', res);
							this.$refs.print.order(res.data, 'printfile')


						}

					}


				} catch (err) {
					this.$message.error('数据请求失败');
				}
			},



			//订单支付
			async orderPay(data, order_number) {
				let res = await this.$api.orderPay(data);
				// console.log('订单', res)
				if (res.code == 200) {
					this.$message.success(res.msg)

					let param = {
						'uid': this.$store.state.userinfo.id,
						'number': order_number,
					}
					this.OrderDetail(param)

				} else {
					this.$message.error(res.msg)
				}
			},


			async sumMoney() {

				try {




					this.sumMoneyData.number = this.wxData.print_number;
					this.sumMoneyData.page = this.wxData.print_count;

					this.sumMoneyData.ctype = this.wxData.print_ctype;
					this.sumMoneyData.paper_type = this.wxData.paper_type;


					this.sumMoneyData.type = this.wxData.print_type;

					if (this.sumMoneyData.type == 7) {
						this.sumMoneyData.type = 9; //照片6寸
						this.wxData.paper_type = '2';
						this.dis_hb = true;
					} else {
						this.dis_hb = false;
					}

					//黑白去底色
					if (this.sumMoneyData.paper_type == '3') {
						this.sumMoneyData.paper_type = 1;
					}

					if (this.wxData.print_ctype == 2) { //双页打印
						this.sumMoneyData.page = Math.ceil(this.wxData.print_count / 2);
					}

					// console.log('支付参数', this.sumMoneyData)

					let res = await this.$api.sumMoney(this.sumMoneyData);

					this.price = res.data
					this.wxData.money_type = res.name;
					// console.log('cc', res)

				} catch {
					this.$message.error('数据请求失败');
				}
			},

			async getFileList() {


				try {
					let res = await this.$api.fileList(this.query);
					this.fileList = res.data.rows;
					this.total = res.data.page_info.total
					this.fileList.map(item => {
						//  // console.log(item)
						if (item.print_type == 2 || item.print_type == 3 || item.print_type == 5 || item
							.print_type == 8) {
							//// console.log(`url:${this.$domain}${item.file_url}`)
							this.srcList.push(`${this.$domain}${item.file_url}`)
						}
					})

					//	// console.log(123, this.srcList)

				} catch {
					this.$message.error('数据请求失败');
				}


			},


			//删除文件
			async handleDelete(id) {
				let that = this

				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {


					let res = await this.$api.fileDel({
						id
					});
					if (res.code == 200) {
						this.$message.success('文件删除成功')
						this.getFileList();
					}



				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});

			},

			async pay(type, enter = 0) {
				this.spec = false;
				if (type == 'wx') {
					this.wxData.type = 2; //打印支付
					let res = await this.$api.scanPay(this.wxData);
					this.code = res.data.code_url;
					// console.log('code', this.code)
					this.showc = true;


					this.timer = setInterval(() => {
						this.scanPaySuccessful(res.data.order_number);
					}, 1000)

				} else {


					/*   // console.log('余额支付');
					return false; */

					this.wxData.ctype = 2; //打印支付
					this.wxData.type = enter; //企业


					//群打印需要先创建任务
					if (this.isGroup) {
						this.saveTask(enter);
						return false;
					}


					console.log('余额支付', this.wxData)
					let res = await this.$api.balancePayment(this.wxData);

					let param = {
						'uid': this.$store.state.userinfo.id,
						'number': res.order_number
					}
					// console.log('参数', param)

					this.OrderDetail(param)


				}

			},



			//支付状态
			async scanPaySuccessful(order) {
				let res = await this.$api.scanPaySuccessful({
					'order_number': order
				});
				// console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);
					this.showc = false;

					let param = {
						'uid': this.$store.state.userinfo.id,
						'number': order
					}
					// console.log('参数', param)

					this.OrderDetail(param)
				}
			},


			async OrderDetail(data) {

				try {
					let res = await this.$api.OrderDetail(data);
					if (res.code == 200) {

						console.log('订单详情', res);
						this.$refs.print.order(res.data, 'printfile')
						return false;

					}


				} catch (err) {
					console.log('err', err)
					this.$message.error('数据请求失败');
				}
			},


			open() {
				this.printOk = true;

				if (this.$store.state.userinfo) {
					//this.$router.go(0)
				} else {
					setTimeout(() => {
						this.$router.push('/RegisterVip')
					}, 3000)

				}

			},

			loginout() {
				sessionStorage.setItem('token', '');
				sessionStorage.setItem('userInfo', null);
				this.$store.commit('setUserinfo', null);
				this.$message.success('用户退出成功');
				this.printOk = false
			},



			previewPic() {

				this.previewLoading = true;

				let self = this;


				let obj = JSON.parse(JSON.stringify(this.wxData));

				console.log('wd', this.wxData);

				let hb = obj.print_file.split('.');
				hb = hb[0] + '_hb.' + hb[1];


				obj.print_hb_file = this.$domain + hb;

				obj.print_file = this.$domain + obj.print_file


				//黑白
				if (obj.paper_type == 1) {
					obj.print_file = obj.print_hb_file
				}


				this.pic = obj.print_file;


				if (obj.task_type == 5 || obj.task_type == 8) { //身份证直接用图片打印
					let LODOP = getLodop();


					if (obj.paper_type == 1) {
						obj.print_file = obj.print_hb_file
					}

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1); //单面打印

					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
					} else {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
					}


					//	return false;

					if (obj.task_type == 8) {
						LODOP.ADD_PRINT_IMAGE("10%", "15%", "100%", "100%", `<div style="width:42cm; height:59.4cm; ">
               		    <img  style="width:510px; height:580px;" src=${obj.print_file}>
               		  </div>`);

					} else {
						LODOP.ADD_PRINT_IMAGE("10%", "25%", "100%", "100%", `<div style="width:42cm; height:59.4cm; ">
               		    <img  style="width:500px; height:630px;" src=${obj.print_file}>
               		  </div>`);
					}

					LODOP.SET_PRINT_STYLEA(0, "FontColor", "#808080");

					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);
					//  LODOP.SET_PRINT_MODE("PRINT_DUPLEX",2);
					LODOP.On_Return = function(TaskID, Value) {
						// console.log("TaskID:" + TaskID);
						// console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
							// console.log("每次轮询的状态：" + self.printStatus);
							if (self.printStatus != 0) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.previewLoading = false;


								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};




					LODOP.SET_PREVIEW_WINDOW(0, 3, 0, 1200, 800, "");
					LODOP.PREVIEW();





					return false;
				}



				//图片a4纸打印
				if (obj.task_type == 2 && obj.print_type == 2) {
					let LODOP = getLodop();
					console.log('图片a4纸打印');

					if (obj.paper_type == 1) {
						obj.print_file = obj.print_hb_file
					}

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1); //单面打印

					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
					} else {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
					}



					//	return false;
					LODOP.ADD_PRINT_IMAGE("0", "0", "100%", "100%",
						` <img style="width:21cm; height:29.7cm" src=${obj.print_file}>`);


					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);
					//  LODOP.SET_PRINT_MODE("PRINT_DUPLEX",2);
					LODOP.On_Return = function(TaskID, Value) {
						// console.log("TaskID:" + TaskID);
						// console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
							// console.log("每次轮询的状态：" + self.printStatus);
							if (self.printStatus != 0) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.previewLoading = false;


								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};





					LODOP.SET_PREVIEW_WINDOW(0, 3, 0, 1200, 800, "");
					LODOP.PREVIEW();



					return false;
				}





				//// console.log('准备打印照片', obj.print_type)
				// console.log('照片参数', obj)
				let boxname = "";
				switch (obj.print_type + '') {
					case '3': //一寸
						boxname = 'box1'
						break;
					case '4': //两寸
						boxname = 'box2'
						break;
					case '5': //大一寸
						boxname = 'box3'
						break;
					case '6': //大两寸
						boxname = 'box4'
						break;
					case '7': //6寸
						boxname = 'box5'
						break;
				}



				this.$nextTick(() => {

					//// console.log('self.LODOP',self.LODOP)

					console.log('88888---', boxname);

					var str = this.$refs[boxname].innerHTML;
					console.log('str', str)
					console.log('pic', self.pic)

					//	return false;

					LODOP = getLodop();

					// console.log('lodop111', LODOP)
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1);
					//寸照片
					//LODOP.SET_PRINT_PAGESIZE(1, '210mm', '297mm', 'A4');
					// console.log('6寸照片')
					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['EPSON WF-C5290 Series']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, '102 x 152 毫米 (4 x 6 英寸)');
					} else {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['HiTi P525L']);
					}



					//LODOP.SET_PRINTER_INDEXA(0);
					LODOP.ADD_PRINT_IMAGE(0, 0, "100%", "100%", str);
					LODOP.SET_PRINT_STYLEA(0, "Stretch", 1);



					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);
					//  LODOP.SET_PRINT_MODE("PRINT_DUPLEX",2);
					LODOP.On_Return = function(TaskID, Value) {
						// console.log("TaskID:" + TaskID);
						// console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
							// console.log("每次轮询的状态：" + self.printStatus);
							if (self.printStatus != 0) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.previewLoading = false;


								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};




					LODOP.SET_PREVIEW_WINDOW(0, 3, 0, 1200, 800, "");
					LODOP.PREVIEW();


					return;

				})
			},





			printFile(obj) {



				let LODOP = getLodop();
				console.log('wd', obj);
				//	return false;

				//黑白
				if (obj.paper_type == 1) {
					obj.print_file = obj.print_hb_file
				}

				//去底色
				if (obj.paper_type == 3) {
					obj.print_file = obj.print_hb_qds_file
				}

				//	console.log('wd', obj);
				//	return false;



				let self = this;

				LODOP.PRINT_INIT("测试PDF打印功能");



				if (localStorage.getItem('model') == '1') {
					if (obj.print_type == 1) { //a3纸
						this.$message.error('对不起，当前设备不支持A3纸打印');
						return false;
					}

					LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);

					if (obj.print_typeset == '1') {
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
					} else {
						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4 210 x 297 毫米');
					}



					//扫描文件统一横向处理
					if (obj.task_type == '6' || obj.task_type == '7') {

						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4 210 x 297 毫米');
					}
				} else {
					LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);

					if (obj.print_type == 1) { //a3纸
						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A3');
						// console.log('打印A3纸张')

					} else { //a4纸张

						if (obj.print_typeset == '1') {
							LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
						} else {
							LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4');
						}


						if (obj.task_type == '6' || obj.print_type == '7') {
							LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4');
						}

					}


				}


				this.loading = true;

				//LODOP.ADD_PRINT_PDF(0, 0, "100%", "100%", 'https://print.ynlothar.com/uploads/20220423/2761bdaa051788d65b7478316b6b0a037e071fb4.pdf');
				LODOP.ADD_PRINT_PDF(0, 0, "100%", "100%", this.demoDownloadPDF(obj.print_file));


				LODOP.SET_PRINT_STYLEA(0, "ScalX", 1); //水平放大0.5倍
				LODOP.SET_PRINT_STYLEA(0, "ScalY", 1);

				if (obj.print_ctype == '2') { //双面打印
					// console.log('设置双面打印222')
					//LODOP.SET_PRINT_MODE("DOUBLE_SIDED_PRINT",true);

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
				} else {
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1);
				}

				LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
				//LODOP.SET_PRINT_STYLE('FontColor','#000')
				LODOP.SET_PRINT_STYLEA(0, "FontColor", "#808080");

				LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);
				//  LODOP.SET_PRINT_MODE("PRINT_DUPLEX",2);
				LODOP.On_Return = function(TaskID, Value) {
					// console.log("TaskID:" + TaskID);
					// console.log("Value:" + Value); //job代码
					self.jobCode = Value;
					var timer = setInterval(function() {
						LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
						// console.log("每次轮询的状态：" + self.printStatus);
						if (self.printStatus != 0) { //打印成功
							clearInterval(timer);
							self.printStatus = 1;
							self.loading = false;
							self.open();

							let data = {
								'task_number': obj.task_number,
								'device_number': localStorage.getItem('device')
							};
							self.accomplish(data);

							return;
						}
						self.getStatusValue(Value);
					}, 1000);
				};

				LODOP.PRINT();

				return;



			},


			async accomplish(data) {

				let res = await this.$api.accomplish(data);
				// console.log(111);
				//this.loading = false;
				//this.TaskList();
			},



			demoDownloadPDF(url) {
				if (!(/^https?:/i.test(url))) return;
				if (window.XMLHttpRequest) var xhr = new XMLHttpRequest();
				else var xhr = new ActiveXObject("MSXML2.XMLHTTP");
				xhr.open('GET', url, false); //同步方式
				if (xhr.overrideMimeType)
					try {
						xhr.responseType = 'arraybuffer';
						var arrybuffer = true;
					} catch (err) {
						xhr.overrideMimeType('text/plain; charset=x-user-defined');
					}
				xhr.send(null);
				var data = xhr.response || xhr.responseBody;
				if (typeof Uint8Array !== 'undefined') {
					if (arrybuffer) var dataArray = new Uint8Array(data);
					else {
						var dataArray = new Uint8Array(data.length);
						for (var i = 0; i < dataArray.length; i++) {
							dataArray[i] = data.charCodeAt(i);
						}
					}
				} else
					var dataArray = VBS_BinaryToArray(data).toArray(); //兼容IE低版本
				return this.demoGetBASE64(dataArray);
			},

			demoGetBASE64(dataArray) {
				var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
				var strData = "";
				for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
					if (isNaN(dataArray[i])) break;
					var b1 = dataArray[i] & 0xFF,
						b2 = dataArray[i + 1] & 0xFF,
						b3 = dataArray[i + 2] & 0xFF;
					var d1 = b1 >> 2,
						d2 = ((b1 & 3) << 4) | (b2 >> 4);
					var d3 = i + 1 < ii ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
					var d4 = i + 2 < ii ? (b3 & 0x3F) : 64;
					strData += digits.substring(d1, d1 + 1) + digits.substring(d2, d2 + 1) + digits.substring(d3, d3 +
							1) +
						digits.substring(d4, d4 + 1);
				}
				return strData;
			},

			getStatusValue(job) { //根据job代码，获取是否打印成功

				var self = this;
				LODOP = getLodop();
				LODOP.On_Return = function(TaskID, Value) {
					// console.log("TaskID:" + TaskID);
					// console.log("打印成功状态:" + Value); //打印成功状态
					self.printStatus = Value;
				};
				LODOP.GET_VALUE("PRINT_STATUS_EXIST", job);
			},


			print(obj) {


				// console.log('照片', obj);
				//	return false;



				let self = this;
				this.loading = true;

				if (obj.task_type == 5 || obj.task_type == 8) { //身份证直接用图片打印
					let LODOP = getLodop();
					// console.log('打印身份证');

					if (obj.paper_type == 1) {
						obj.print_file = obj.print_hb_file
					}

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1); //单面打印

					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
					} else {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
					}


					//	return false;

					if (obj.task_type == 8) {
						LODOP.ADD_PRINT_IMAGE("10%", "15%", "100%", "100%", `<div style="width:42cm; height:59.4cm; ">
						    <img  style="width:510px; height:580px;" src=${obj.print_file}>
						  </div>`);

					} else {
						LODOP.ADD_PRINT_IMAGE("10%", "25%", "100%", "100%", `<div style="width:42cm; height:59.4cm; ">
						    <img  style="width:500px; height:630px;" src=${obj.print_file}>
						  </div>`);
					}



					LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);



					LODOP.On_Return = function(TaskID, Value) {
						// console.log("TaskID:" + TaskID);
						// console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							// console.log("每次轮询的状态：" + self.printStatus);
							if (self.printStatus != 0) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.loading = false;
								self.open();

								let data = {
									'task_number': obj.task_number,
									'device_number': localStorage.getItem('device')
								};
								self.accomplish(data);

								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};

					LODOP.PRINT();



					return false;
				}



				//图片a4纸打印
				if (obj.task_type == 2 && obj.print_type == 2) {
					let LODOP = getLodop();
					console.log('图片a4纸打印');

					if (obj.paper_type == 1) {
						obj.print_file = obj.print_hb_file
					}

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1); //单面打印

					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
					} else {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
					}



					//	return false;
					LODOP.ADD_PRINT_IMAGE("0", "0", "100%", "100%",
						` <img style="width:21cm; height:29.7cm" src=${obj.print_file}>`);


					LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);



					LODOP.On_Return = function(TaskID, Value) {
						console.log("TaskID:" + TaskID);
						console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							console.log("每次轮询的状态：" + self.printStatus);
							if (self.printStatus != 0) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.loading = false;
								self.open();

								let data = {
									'task_number': obj.task_number,
									'device_number': localStorage.getItem('device')
								};
								self.accomplish(data);

								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};
					LODOP.PRINT();




					return false;
				}





				//// console.log('准备打印照片', obj.print_type)
				// console.log('照片参数', obj)
				let boxname = "";
				switch (obj.print_type) {
					case 3: //一寸
						boxname = 'box1'
						break;
					case 4: //两寸
						boxname = 'box2'
						break;
					case 5: //大一寸
						boxname = 'box3'
						break;
					case 6: //大两寸
						boxname = 'box4'
						break;
					case 7: //6寸
						boxname = 'box5'
						break;
				}



				this.$nextTick(() => {

					//// console.log('self.LODOP',self.LODOP)



					var str = this.$refs[boxname].innerHTML;
					console.log('str', str)
					//	return false;

					LODOP = getLodop();

					// console.log('lodop111', LODOP)
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1);
					//寸照片
					//LODOP.SET_PRINT_PAGESIZE(1, '210mm', '297mm', 'A4');
					// console.log('6寸照片')
					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['EPSON WF-C5290 Series']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, '102 x 152 毫米 (4 x 6 英寸)');
					} else {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['HiTi P525L']);
					}



					//LODOP.SET_PRINTER_INDEXA(0);
					LODOP.ADD_PRINT_IMAGE(0, 0, "100%", "100%", str);
					LODOP.SET_PRINT_STYLEA(0, "Stretch", 1);
					LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
					// console.log('打印份数',obj.print_number)


					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);

					LODOP.On_Return = function(TaskID, Value) {
						// console.log("TaskID:" + TaskID);
						// console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							// console.log("每次轮询的状态：" + self.printStatus);
							if (self.printStatus == 0) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.loading = false;
								self.open();

								let data = {
									'task_number': obj.task_number,
									'device_number': localStorage.getItem('device')
								};
								self.accomplish(data);


								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};
					LODOP.PRINT();



					return;

				})
			},





			//打印
			handlePrint(obj) {
				this.isGroup = false;
				this.ids = [];

				console.log('obj1111', obj)
				this.currObj = obj;
				this.wxData.print_file = obj.file_url;
				this.wxData.print_hb_file = obj.file_hb_url;
				this.wxData.print_hb_qds_file = obj.file_hb_qds_url;

				this.wxData.type = 2; //打印
				this.wxData.paper_type = '2'; //彩色

				this.wxData.print_count = obj.page; //页码



				//尺寸
				if (obj.print_type == '1') {
					this.wxData.print_type = '2'; //a4
					this.wxData.task_type = '1'; //文档

				} else if (obj.print_type == '3') { //证件照
					this.wxData.print_type = '3'; //1寸
					this.wxData.task_type = '3';

				} else if (obj.print_type == '2') { //照片
					this.wxData.print_type = '2'; //6寸
					this.wxData.task_type = '2'; //

				} else if (obj.print_type == '5') { //身份证
					this.wxData.print_type = '2'; //a4
					this.wxData.task_type = '5'; //

				}


				if (obj.print_type == '8') { //复印身份证
					this.wxData.print_type = '2'; //a4
					this.wxData.task_type = '8'; //

				}

				if (obj.print_type == '6') { //复印文件
					this.wxData.task_type = '6'; //

				}

				if (obj.print_type == '7') { //扫描文件
					this.wxData.task_type = '6'; //

				}

				console.log('参数wxData', this.wxData)

				//this.sumMoney = "";
				this.sumMoney();


				this.spec = true;
			},


			handleCurrentChange(e) {
				this.query.page = e;
				this.getFileList()
			},

		}
	}

	//STORE iR-ADV C5235
	//STORE HiTi P525L
</script>

<style scoped lang="scss">
	.page {
		width: 100%;
		background-color: #fff;

		/* margin-top: 30px; */
	}

	.con {
		padding-top: 50px;
		height: 80vh;

		.pointpage {
			margin-top: 50px;
		}

		.photobox {
			display: none;
		}
	}


	::v-deep .el-loading-spinner {
		p {
			font-size: 50px;
			color: #fff;
		}

		i {
			font-size: 100px;
			color: #fff;
		}

	}
</style>
